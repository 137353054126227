import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import cookie from 'js-cookie'
import { camelizeKeys, decamelizeKeys } from 'humps'

// axios.defaults.headers.common['X-CSRF-Token'] = cookie.get('XSRF-TOKEN') || ''
// axios.defaults.headers.common['Accept'] = 'application/json'
const defaultHTTPClient: AxiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': cookie.get('XSRF-TOKEN') || '',
  },
})

const apiClient: AxiosInstance = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-type': 'application/json',
    'X-CSRF-Token': cookie.get('XSRF-TOKEN') || '',
  },
})

const isoDateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value)) body[key] = new Date(value)
    else if (typeof value === 'object') handleDates(value)
  }
}

/**
 * Default processor injected into Axions interceptor
 * It will camelize keys and transform all date into date object
 *
 * @param {Object} response
 * @returns {Object}
 */
export function apiResponseTransformer(response: any): any {
  try {
    handleDates(response)
    return camelizeKeys(response)
  } catch (error) {
    return error
  }
}

// Axios middleware to convert all api responses to camelCase
apiClient.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data &&
    response.headers['content-type'].match('application/json')
  ) {
    response.data = apiResponseTransformer(response.data)
  }
  return response
})

// Axios middleware to convert all api requests to snake_case
apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config }
  if (
    config.headers &&
    config.headers['Content-Type'] === 'multipart/form-data'
  )
    return newConfig
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }
  return newConfig
})

export type ApiRequestInterface = {
  page?: number
  per_page?: number
  client_id?: number
}

export type IApiErrorResponse = {
  error?: string
  errors?: {
    [index: string]: Array<string>
  }
}

export { apiClient, defaultHTTPClient }
