import { camelizeKeys } from 'humps'

export function extractMetaData(tag: string): string {
  const metaData = document.querySelector<HTMLMetaElement>(
    `meta[name="${tag}"]`
  )
  return metaData ? metaData.content : ''
}

export function extractJsonMetaData(tag: string, cKeys = false): any {
  const parsedData = JSON.parse(extractMetaData(tag))
  if (!cKeys) return parsedData

  return camelizeKeys(parsedData)
}
