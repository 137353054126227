<template>
  <div class="wrapper">
    <!-- {{> header }} -->

    <div class="section-email-validation">
      <div class="shell">
        <div class="section__inner">
          <div class="section__head">
            <div class="section__head-title">
              <h1>Free Email Verification</h1>
            </div>

            <div class="section__head-logo">
              <img src="/images/ev_form/temp/logo.png" alt="" />
            </div>
          </div>

          <div class="section__form active" v-if="!limitExceeded">
            <div class="form-email">
              <div class="form__head">
                <h2>Quickly and accurately verify whether an <br>email is valid and can receive email</h2>
              </div>

              <div class="form__content">
                <div class="form__content-inner">
                  <div class="form__title">
                    <h2>Enter the Email Address to Verify</h2>
                  </div>

                  <div class="form__body">
                    <div class="form__row">
                      <label for="field-email" class="form__label">Email</label>

                      <div class="form__controls">

                        <input v-model="email" type="text" class="field" name="field-email" id="field-email"
                          placeholder="user@domain.com" novalidate autocomplete="off" @keyup.enter="requestForm()">
                      </div>
                    </div>
                  </div>

                  <div class="form__actions">
                    <button @click='requestForm()' class="form__btn btn"
                      :class="actionInProgress ? 'load-in-progress' : ''">
                      <span v-show="actionInProgress">
                        Verifying
                        <img src="/images/ev_form/svg/spinner.svg">
                      </span>
                      <span v-show="!actionInProgress">
                        Verify Email
                      </span>

                    </button>
                  </div>
                  <div class="error-message" v-if="error">{{ error }}</div>
                </div>
              </div>
            </div>
          </div>

          <ResultSection :validation-data-results="results" v-if="results" />
          <div class="section__form active" v-if="limitExceeded">
            <div class="form-email form-email--alt">
              <div class="form__head">
                <h2>Quickly and accurately verify whether an <br>email is valid and can receive email</h2>
              </div>

              <div class="form__content">
                <div class="form__content-inner">
                  <h3>If you would like to validate additional email addresses:</h3>
                  <div class="form__content-actions">
                    <a href="https://instantdata.atdata.com/?ref=evcom&utm_source=emailverification.com&utm_medium=website&utm_term=register_for_free_account_cta" target="_blank" class="btn">Register for a Free Account</a>
                    <a href="https://www.atdata.com/contact-us/?utm_source=emailverification.com&utm_medium=website&utm_term=contact_atdata_team " target="_blank" class="btn btn--secondary">Contact Our Team</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section-features">
      <div class="shell">
        <div class="section__inner">
          <div class="grid">
            <div class="grid__col grid__col--1of4">
              <div class="feature">
                <div class="feature__image">
                  <img src="/images/ev_form/svg/clipboard-icon.svg" alt="" width="81">
                </div>

                <div class="feature__content">
                  <p>Filter out invalid and high-risk email addresses; pinpoint invalid email formats and fix address
                    misspellings</p>
                </div>
              </div>
            </div>

            <div class="grid__col grid__col--1of4">
              <div class="feature">
                <div class="feature__image">
                  <img src="/images/ev_form/svg/shield-icon.svg" alt="" width="90">
                </div>

                <div class="feature__content">
                  <p>Protect against bad domains, spam traps, honeypots, and high-risk emails</p>
                </div>
              </div>
            </div>

            <div class="grid__col grid__col--1of4">
              <div class="feature">
                <div class="feature__image">
                  <img src="/images/ev_form/svg/validation-icon.svg" alt="" width="125">
                </div>

                <div class="feature__content">
                  <p>Determine whether an address will soft bounce, hard bounce or deliver (with a mailbox check)</p>
                </div>
              </div>
            </div>

            <div class="grid__col grid__col--1of4">
              <div class="feature">
                <div class="feature__image">
                  <img src="/images/ev_form/svg/email-batch-icon.svg" alt="" width="105">
                </div>

                <div class="feature__content">
                  <p>Distinguish good emails from bad and understand why using our 30 diagnostic status codes to
                    understand why</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-outro">
      <div class="shell">
        <div class="section__inner">
          <h2>Want to validate more emails in bulk for a pristine email list and improve open rates, clicks, and
            conversions?</h2>
          <p>Sign up for a free account with the "Email Address Experts" <br>to check out our full suite of offerings.</p>
          <a href="https://www.atdata.com/email-verification/?utm_source=emailverification.com&utm_medium=website&utm_term=learn_more_cta" target="_blank" class="btn">Learn More</a>
        </div>
      </div>
    </section>

    <footer class="footer">
      <div class="shell">
        <div class="footer__inner">
          <div class="footer__nav">
            <ul>
              <li>
                <p>
                  © {{ new Date().getFullYear() }} AtData, All Rights Reserved
                </p>
              </li>

              <li>
                <a href="https://www.atdata.com/privacy-policy" target="_blank">Privacy Policy</a>
              </li>

              <li>
                <a href="https://instantdata.atdata.com/optout" target="_blank">Do Not Sell My Personal Information</a>
              </li>

              <li>
                <a href="https://www.atdata.com/security" target="_blank">Security</a>
              </li>

              <li>
                <a href="https://www.atdata.com/gdpr" target="_blank">GDPR</a>
              </li>

              <li>
                <a href="https://www.atdata.com/terms-of-service" target="_blank">Terms of Service</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import '@/stylesheets/ev_form/style.scss'
// import { VueReCaptcha } 'vue-recaptcha-v3'


import { defaultHTTPClient } from '@/utils/http-common'

import { AxiosError } from 'axios'
import { extractJsonMetaData } from '@/utils/meta-init'
import { ref } from 'vue'
import Cookies from 'js-cookie'
import { IResult } from '@/services/ev_form/Result'
import ResultSection from '@/components/ev_form/ResultSection.vue'
const email = ref<String>("")
const results = ref<IResult | null>(null)
const recaptchaEnabled = extractJsonMetaData('protectWithRecaptcha') as Boolean
const error = ref<string | null>(null)
const COOKIE_CHECK = 'daily-limit'
const limitExceeded = ref<Boolean>(false)
const actionInProgress = ref<Boolean>(false)
limitExceeded.value = !!Cookies.get(COOKIE_CHECK)



import { useReCaptcha } from "vue-recaptcha-v3"
const recaptchaModel = recaptchaEnabled ? useReCaptcha() : undefined

const requestForm = async () => {
  if (actionInProgress.value || email.value.trim().length === 0) return;

  let request = { 'email': email.value.trim() } as { [index: string]: any }
  if (recaptchaModel && recaptchaEnabled) {
    await recaptchaModel.recaptchaLoaded()
    const tokenV3 = await recaptchaModel.executeRecaptcha('ev_form')
    if (tokenV3) { request['g-recaptcha-response-data'] = tokenV3 }
  }

  results.value = null
  error.value = null
  actionInProgress.value = true
  defaultHTTPClient.post<IResult>('/ev_form', request).then(function (response) {
    results.value = response.data
    setTimeout(() => { actionInProgress.value = false }, 1000)

  }).catch(function (err: AxiosError) {
    results.value = null
    setTimeout(() => { actionInProgress.value = false }, 1000)
    if (err.response?.status === 429) {
      Cookies.set(COOKIE_CHECK, new Date().toISOString(), { expires: 1 })
      limitExceeded.value = true
    } else {
      error.value = "Sorry! We can't process your request. Try later."

    }
  })
}
</script>
<style scoped>
.recaptcha {
  display: inline-block;
}

input:focus::placeholder {
  color: transparent;
}

.load-in-progress {
  background-color: white;
  color: #00aeef;
}

.error-message {
  padding: 20px 0 0 0;
}
</style>
