<template>
  <div class="section__result active" @click="resetTips()">
    <div class="widget-result" v-if='validationDataResults'>
      <div class="widget__head" :class="backgroundStatus()">
        <div class="widget__image">
          <img :src="backgroundImage()" alt="" width="182">
        </div>

        <div class="widget__info">
          <ul>
            <li>
              <div>
                <h2>EMAIL:</h2>
              </div>
              <div>
                <a class="disabled-email" href="javascript:void(0);" v-if="validationDataResults.status_code > 50">{{
                  validationDataResults?.address }}</a>
                <a v-if="validationDataResults.status_code <= 50" :href="`mailto:${validationDataResults?.address}`">{{
                  validationDataResults?.address }}</a>
              </div>
            </li>

            <li>
              <div>
                <h2>STATUS:</h2>
              </div>
              <div>
                <h1>{{ fieldPrettyName('status', validationDataResults.status) }}</h1>
                <p> {{ statusMessage() }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="widget__body">
        <div class="grid">
          <div class="grid__col grid__col--1of4" :class="tips.domainType ? 'front' : ''">
            <div class="widget__item">
              <h2>Domain Type</h2>
              <h1>{{ fieldPrettyName('domain_type', validationDataResults?.domain_type) || '---' }}</h1>
              <!-- <Tooltip title="What is a domain type?" description="Domain type indicates the type of the domain including, “disposable”, “freeisp”, etc. Refer to Domain -->
              <!-- Types for a full list of domains and their descriptions." ></Tooltip> -->
              <div class="widget__item-actions js-tooltop" :class="tips.domainType ? 'tooltip-open' : ''">


                <div v-show='!domainTypeTooltip'>
                  <a href="javascript:void(0);" @click="tipEnable('domainType')">What is a domain type?</a>
                  <div class="widget__item-tooltip">
                    <p>Domain type indicates the type of the domain including, “disposable”, “freeisp”, etc. Refer to
                      Domain
                      Types for a full list of domains and their descriptions.</p>
                  </div>
                </div>


                <div v-show='domainTypeTooltip'>
                  <a href="javascript:void(0);" @click="tipEnable('domainType')">What is this domain type?</a>
                  <div class="widget__item-tooltip">
                    <p>{{ domainTypeTooltip }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid__col grid__col--1of4" :class="tips.roleAccount ? 'front' : ''">
            <div class="widget__item">
              <h2>Role Account</h2>
              <h1>{{ validationDataResults?.role_account ? (validationDataResults.role_account ? "True" : "False") :
                "---" }}</h1>
              <div class="widget__item-actions js-tooltop" :class="tips.roleAccount ? 'tooltip-open' : ''">
                <a href="javascript:void(0);" @click="tipEnable('roleAccount')">What is role account?</a>
                <div class="widget__item-tooltip">
                  <p> A role account is an email address for a business job role or a group of people in a company such as
                    sales, info, support, marketing or customer service (e.g. info@abc.com). Because role accounts are not
                    intended for a single person, commercial emails are usually flagged as spam. </p>
                </div>
              </div>
            </div>
          </div>

          <div class="grid__col grid__col--1of4" :class="tips.dataFirstSeen ? 'front' : ''">
            <div class="widget__item">
              <h2>Date First Seen</h2>
              <h1>{{ validationDataResults?.date_first_seen || '---' }}</h1>
              <div class="widget__item-actions js-tooltop" :class="tips.dataFirstSeen ? 'tooltip-open' : ''">
                <a href="javascript:void(0)" @click="tipEnable('dataFirstSeen')">What is date first seen?</a>
                <div class="widget__item-tooltip">
                  <p>
                    The date the email address first appeared in our records, and it gives an idea of the age of an email
                    address. The value “now” will be returned if the email address has not been seen before.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="grid__col grid__col--1of4" :class="tips.monthLastOpen ? 'front' : ''">
            <div class="widget__item">
              <h2>Month Last Open</h2>
              <h1>{{ validationDataResults?.month_last_open || '---' }} </h1>
              <div class="widget__item-actions js-tooltop" :class="tips.monthLastOpen ? 'tooltip-open' : ''">
                <a href="javascript:void(0)" @click="tipEnable('monthLastOpen')">What is month last open?</a>
                <div class="widget__item-tooltip">
                  <p>The month that our system last detected an open by this email address in YYYY-MM format.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IResult } from '@/services/ev_form/Result'
import { IDataField, FIELDS_MAPPINGS, DOMAIN_TYPES, STATUS_MESSAGES } from '@/services/ev_form/DataField'
import { computed, reactive } from '@vue/reactivity';

const props = defineProps<{
  validationDataResults: IResult | null
}>()


const findDataField = function (dataField: string): IDataField | undefined {
  return FIELDS_MAPPINGS.find((item) => {
    return item.name === dataField
  })
}
const fieldPrettyName = function (dataField: string, key: any): string | undefined {
  const df = findDataField(dataField)
  if (!df) return undefined
  return df.fieldValues.find((value) => {
    return value.value === key
  })?.prettyName
}
const backgroundStatus = function (): string {
  if (!props.validationDataResults) return ''
  const statusCode = props.validationDataResults.status_code

  if (statusCode > 50) {
    return 'invalid'
  } else if (statusCode === 5 || statusCode === 45) {
    return 'unknown'
  } else {
    return 'validated'
  }
}


const statusMessage = function (): string {

  if (!props.validationDataResults) return ''
  const status = props.validationDataResults.status

  if (status === 'invalid') {
    const lat = fieldPrettyName('status_code', props.validationDataResults.status_code.toString(10)) || ""
    return `Do not mail. ${lat}`
  }

  const result: string = STATUS_MESSAGES[status]

  return result || STATUS_MESSAGES.unknown
}
const backgroundImage = function () {
  return new URL(`/images/ev_form/svg/email-${backgroundStatus()}-icon.svg`, import.meta.url).href
}

type ITooltipTypes =
  'domainType' |
  'roleAccount' |
  'dataFirstSeen' |
  'monthLastOpen'

let ltips: Record<ITooltipTypes, boolean> = {
  domainType: false,
  roleAccount: false,
  dataFirstSeen: false,
  monthLastOpen: false
}

const domainTypeTooltip = computed((): string | null => {
  if (!props.validationDataResults?.domain_type) return null

  return DOMAIN_TYPES[props.validationDataResults.domain_type as keyof typeof DOMAIN_TYPES]
})

const tips = reactive(ltips)
let lock = false
const tipEnable = function (tip: ITooltipTypes) {
  lock = true
  Object.keys(tips).forEach((key) => {
    let v = tips[key as keyof typeof ltips]
    if (key === tip) {
      v = !v
    } else {
      v = false
    }
    tips[key as keyof typeof ltips] = v
  })
  setTimeout(() => {
    lock = false
  }, 500)
}

const resetTips = function () {
  if (lock) return;

  setTimeout(() => {
    Object.keys(tips).forEach((key) => {
      tips[key as keyof typeof ltips] = false
    })
  }, 500)
}

</script>
<style scoped>
.validated {
  background: linear-gradient(to right, rgb(174, 209, 74) 0%, rgb(39, 170, 121) 100%);
}

.invalid {
  background: linear-gradient(to right, #f78d2d 0%, #cb333b 100%);
}

.unknown {
  background: linear-gradient(to right, #00aeef 0%, #373896 100%);
}

.disabled-email {
  pointer-events: none;
  cursor: default;
}
</style>
