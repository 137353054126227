
export type IFieldValue = {
  value: string
  prettyName: string
}
export type IDataField = {
  name: string
  prettyName: string
  description: string | null
  fieldValues: Array<IFieldValue>
}

export const FIELDS_MAPPINGS: IDataField[] =
  [
    {
      name: "status",
      prettyName: "Validation Status",
      description: null,
      fieldValues: [
        {
          value: "corrected",
          prettyName: "Corrected"
        },
        {
          value: "invalid",
          prettyName: "Invalid"
        },
        {
          value: "risky",
          prettyName: "Risky"
        },
        {
          value: "safetosend",
          prettyName: "SafeToSend"
        },
        {
          value: "trap",
          prettyName: "Trap"
        },
        {
          value: "unknown",
          prettyName: "Unknown"
        },
        {
          value: "unverifiable",
          prettyName: "Unverifiable"
        },
        {
          value: "valid",
          prettyName: "Valid"
        },
        {
          value: "catchall",
          prettyName: "Catchall"
        },

      ]
    },
    {
      name: "domain_type",
      prettyName: "Domain Type",
      description: null,
      fieldValues: [
        {
          value: "biz",
          prettyName: "Business"
        },
        {
          value: "disposable",
          prettyName: "Disposable"
        },
        {
          value: "edu",
          prettyName: "Education"
        },
        {
          value: "freeisp",
          prettyName: "Free ISP"
        },
        {
          value: "gov",
          prettyName: "Government"
        },
        {
          value: "null",
          prettyName: "Unknown"
        },
        {
          value: "org",
          prettyName: "Non-profit"
        },
        {
          value: "paidisp",
          prettyName: "Paid ISP"
        },
        {
          value: "parked",
          prettyName: "Parked"
        },
        {
          value: "privacy",
          prettyName: "Privacy"
        },
        {
          value: "wireless",
          prettyName: "Wireless"
        }
      ]
    },
    {
      name: "status_code",
      prettyName: "Validation Code",
      description: null,
      fieldValues: [
        {
          value: "10",
          prettyName: "Syntax OK."
        },
        {
          value: "100",
          prettyName: "General syntax error."
        },
        {
          value: "110",
          prettyName: "Invalid character in address."
        },
        {
          value: "115",
          prettyName: "Invalid domain syntax."
        },
        {
          value: "120",
          prettyName: "Invalid username syntax."
        },
        {
          value: "125",
          prettyName: "Invalid username syntax for that domain."
        },
        {
          value: "130",
          prettyName: "Address is too long."
        },
        {
          value: "135",
          prettyName: "Address has unbalanced parentheses, brackets, etc."
        },
        {
          value: "140",
          prettyName: "Address does not have a username."
        },
        {
          value: "145",
          prettyName: "Address does not have a domain."
        },
        {
          value: "150",
          prettyName: "Address does not have an @ sign."
        },
        {
          value: "155",
          prettyName: "Address has more than one @ sign."
        },
        {
          value: "20",
          prettyName: "Syntax OK and domain valid."
        },
        {
          value: "200",
          prettyName: "Invalid top-level-domain (TLD) in address."
        },
        {
          value: "205",
          prettyName: "IP address not allowed as domain."
        },
        {
          value: "210",
          prettyName: "Address contains space or extra text."
        },
        {
          value: "215",
          prettyName: "Unquoted spaces are not allowed in email addresses."
        },
        {
          value: "255",
          prettyName: "Address is not allowed by client-configured suppressions."
        },
        {
          value: "310",
          prettyName: "Domain does not exist."
        },
        {
          value: "315",
          prettyName: "Domain does not have a valid IP address."
        },
        {
          value: "325",
          prettyName: "Domain cannot receive email."
        },
        {
          value: "400",
          prettyName: "Mailbox does not exist."
        },
        {
          value: "410",
          prettyName: "Mailbox is full and can not receive email."
        },
        {
          value: "420",
          prettyName: "Mail is not accepted for this domain."
        },
        {
          value: "45",
          prettyName: "Domain does not support validation (accepts all mailboxes)."
        },
        {
          value: "5",
          prettyName: "Timeout."
        },
        {
          value: "50",
          prettyName: "Valid email address."
        },
        {
          value: "500",
          prettyName: "Emails with that username are not accepted."
        },
        {
          value: "505",
          prettyName: "Emails with that domain are not accepted."
        },
        {
          value: "510",
          prettyName: "That email address is not accepted."
        },
        {
          value: "515",
          prettyName: "Address was rejected during our manual review process."
        },
        {
          value: "520",
          prettyName: "The email address is known to bounce."
        },
        {
          value: "525",
          prettyName: "Address is a spam trap or known complainer."
        },
        {
          value: "530",
          prettyName: "Address has opted out from commercial email."
        },
        {
          value: "535",
          prettyName: "Address is on ANA's \"Do Not Email List\"."
        },
        {
          value: "55",
          prettyName: "Address is allowed by client-configured exceptions."
        },
        {
          value: "999",
          prettyName: "Internal error."
        }
      ]
    },
    {
      name: "date_first_seen",
      prettyName: "Date First Seen",
      description: "The date that AtData first encountered the email address.",
      fieldValues: [
        {
          value: "0",
          prettyName: "New Email"
        },
        {
          value: "1",
          prettyName: "Less than 1 month"
        },
        {
          value: "2",
          prettyName: "Less than a year"
        },
        {
          value: "3",
          prettyName: "1 year"
        },
        {
          value: "4",
          prettyName: "2 years"
        },
        {
          value: "5",
          prettyName: "3 years"
        },
        {
          value: "6",
          prettyName: "4 years"
        },
        {
          value: "7",
          prettyName: "5-10 years"
        },
        {
          value: "8",
          prettyName: "10-15 years"
        },
        {
          value: "9",
          prettyName: "15+ years"
        }
      ]
    },
    {
      name: "popularity",
      prettyName: "Popularity",
      description: "A score from 0 - 10 reflecting the popularity of the email across AtData clients in the past 12 months.",
      fieldValues: [
        {
          value: "0",
          prettyName: "0"
        },
        {
          value: "1",
          prettyName: "1"
        },
        {
          value: "10",
          prettyName: "10"
        },
        {
          value: "2",
          prettyName: "2"
        },
        {
          value: "3",
          prettyName: "3"
        },
        {
          value: "4",
          prettyName: "4"
        },
        {
          value: "5",
          prettyName: "5"
        },
        {
          value: "6",
          prettyName: "6"
        },
        {
          value: "7",
          prettyName: "7"
        },
        {
          value: "8",
          prettyName: "8"
        },
        {
          value: "9",
          prettyName: "9"
        }
      ]
    },
    {
      name: "month_last_open",
      prettyName: "Month Last Open",
      description: "The month that AtData last detected an open by the email address in YYYY-MM format.",
      fieldValues: [
        {
          value: "0",
          prettyName: "0"
        },
        {
          value: "1",
          prettyName: "1"
        },
        {
          value: "10",
          prettyName: "10"
        },
        {
          value: "11",
          prettyName: "11"
        },
        {
          value: "12",
          prettyName: "12+"
        },
        {
          value: "2",
          prettyName: "2"
        },
        {
          value: "3",
          prettyName: "3"
        },
        {
          value: "4",
          prettyName: "4"
        },
        {
          value: "5",
          prettyName: "5"
        },
        {
          value: "6",
          prettyName: "6"
        },
        {
          value: "7",
          prettyName: "7"
        },
        {
          value: "8",
          prettyName: "8"
        },
        {
          value: "9",
          prettyName: "9"
        }
      ]
    },
    {
      name: "longevity",
      prettyName: "Longevity",
      description: "A score from 0 - 3 describing when AtData first encountered the email address.",
      fieldValues: [
        {
          value: "0",
          prettyName: "0"
        },
        {
          value: "1",
          prettyName: "1"
        },
        {
          value: "2",
          prettyName: "2"
        },
        {
          value: "3",
          prettyName: "3"
        }
      ]
    }
  ]

export const DOMAIN_TYPES = {
  biz: 'Business domains are the domains of companies.',
  disposable: 'Disposable domains are used for temporary email addresses used to avoid receiving email.',
  edu: 'Education domains belong to educational institutions.',
  freeisp: 'Free ISP domains belong to Internet Service Providers that provide free email services.',
  gov: 'Government domains belong to governmental institutions.',
  org: 'Non - Profit domains belong to non - profit organizations.',
  paidisp: 'Paid ISP domains belong to Internet Service Provider that charge a fee for their email services.',
  parked: 'Parked domains do not have an active website.',
  privacy: 'Privacy domains are used to protect the privacy of the user.',
  wireless: 'Wireless domains are used for wireless services and are protected by the FCC.Do not send unsolicited emails to them.',
  null: 'Unknown'
}

export const STATUS_MESSAGES = {
  'valid': 'The email address passed the majority of SafeToSend checks; however, the mailbox could not be confirmed as deliverable and may still bounce',
  'safetosend': 'The email address passed all SafeToSend checks, is safe to mail, and is backed by our deliverability guarantee.',
  'invalid': 'Do not mail. The email does not have proper syntax, the domain is dead or the mailbox doesn\'t exist.',
  'trap': 'The email address is valid but it may cause delivery issues (e.g. spamtrap, honeypot, disposable, or complainer). We recommend that you don\'t email trap addresses.',
  'catchall': 'We could not verify this email because its domain doesn’t support a mailbox level check. Also known as an "accept all" domain. The address may bounce if you mail it.',
  'unknown': 'We were not able to complete any checks on this address, and a message to it may bounce. Trying again later may provide a valid/invalid status.'
}
