import { createApp } from 'vue'
import EvForm from '@/views/ev_form/EvForm.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import { extractMetaData, extractJsonMetaData } from '@/utils/meta-init'

const siteKey = extractMetaData('recaptchaSiteKey')
const recaptchaEnabled = extractJsonMetaData('protectWithRecaptcha') as Boolean

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(EvForm)
  if (recaptchaEnabled) {
    app.use(VueReCaptcha, { siteKey: siteKey, loaderOptions: { explicitRenderParameters: { size: 'invisible' } } })
  }
  app.mount('#ev_form')
})
